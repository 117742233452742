<template>
  <div>
    <div class="content">
      <div class="zc">
        <div class="zc_x">
          <img class="score" src="@/assets/icon/downla.png" />
          发布资源<span class="down">{{ transaction.download }}</span>
        </div>
        <div class="zc_x">
          <img class="score" src="@/assets/icon/Gold1.png" />
          可用知识币 <span class="down">{{ transaction.zhishi }}</span>
        </div>
      </div>
      <div class="resour">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item
            ><router-link to="pcresourxce">我的资源</router-link></el-menu-item
          >
          <el-menu-item
            ><router-link to="pcdownload">点击上传</router-link></el-menu-item
          >
        </el-menu>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      transaction: {
        img: require("@/assets/timg (9).png"),
        name: "aaa",
        zhishi: "10",
        download: "200",
      },
      activeName: "first",
      activeIndex: "1",
    };
  },
  methods: {},
};
</script>

<style scoped>
.Resoure_pic {
  width: 173px;
  background-color: #ffffff;
  /* overflow: hidden; */
  float: left;
  text-align: center;
  margin-left: 50px;
  border: 1px solid rgba(210, 210, 210, 1);
}
.picing {
  width: 110px;
  height: 110px;
  border-radius: 999px;
  margin: 33px 33px 0px 33px;
}
.name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;

  line-height: 50px;
  width: 100%;
}
.Resoure_pic button {
  width: 100%;
}
.Resoure_list {
  float: left;
  width: 765px;
  background-color: #ffffff;
  margin-left: 2px;
}
.content {
  float: left;
  width: 600px;
  background-color: #ffffff;
}
.el-menu-item {
  margin-left: 90px;
  margin-right: 90px;
}
.el-menu-demo {
  border: 1px solid #f7f7f7;
}
.resour {
  margin: 10px;
  border: 1px solid rgba(210, 210, 210, 1);
}
.zc {
  width: 100%;
  overflow: hidden;
}
.zc img {
  width: 14px;
}
.zc_x {
  float: right;
  margin: 5px 30px 0px 10px;
}
.el-form {
  margin: 30px;
}
</style>
